import React, { useEffect, useState } from "react"
import Thumb from './thumb';
import Title from '../title';
import Button from 'components/button'
import ConditionalLinkWrapper from '../../components/conditionalLinkWrapper';
import { getBucketUrl } from '../../service';

const Thumbs = props => {
  const [thumbsList, setThumbsList] = useState([]);

  const modifier = props.modifier ? 'c-cards-wrapper--initiative' : "";

  useEffect(() => {
    setThumbsList(props.thumbs);
  }, [props.thumbs])

  return (
    <div className="u-wrapper">
      <div className="c-initiative">
        {props.title && (
          <Title
            center={true}
            content={props.title}
          />
        )}
        {props.subtitle && (
          <p className="o-headline">{props.subtitle}</p>
        )}
        <div className={`c-cards-wrapper ${modifier}`}>
          {thumbsList.map((result, index) => (
            <ConditionalLinkWrapper link={result.hasOwnProperty("link") ? result.link : null} key={index}>
              <Thumb
                title={result.title}
                text={result.text}
                image={getBucketUrl(result.image.path)}
              />
            </ConditionalLinkWrapper>
          ))}
        </div>
        {props.button && (
          <div className="c-initiative__button">
            <Button to={props.button.link} content={props.button.text} type="primary" />
          </div>
        )}
      </div>
    </div>
  )
}

export default Thumbs
