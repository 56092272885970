import React, { useEffect, useState } from "react"
import Layout from 'components/layout/layout';
import BannerTopo from '../../components/bannerTopo';
import InfosPage from '../../components/InfosPage';
import CardsCarousel from '../../components/cardCarousel';
import Title from '../../components/title';
import Thumbs from '../../components/thumbs';
import { getBucketUrl } from '../../service';

const Cultura = () => {

  const [api, setApi] = useState({});

  const init = (apiData) => {
    setApi(apiData);
  }

  useEffect(() => {
  }, []);

  return (
    <div>
      <Layout loadedCallback={init} pageTitle="Cultura" pageDescription="Conheça os pilares de cultura do ecossistema Stone Co., que busca empoderar o empreendedor na gestão do seu negócio.">
        {api && api.constructor === Object && Object.keys(api).length !== 0 && (
          <>
            <InfosPage titlePage="Cultura" text={api.singletons.culture.text} />
            <BannerTopo image={getBucketUrl(api.singletons.culture.image.path)} />
            <div className="u-wrapper">
              <Title center={true} content={api.singletons.culture.title_other_1} />
              <p className="o-headline u-text-center">{api.singletons.culture.text_other_1}</p>
            </div>
            <Thumbs
              thumbs={api.collections.cornerstones}
            />
            <div className="u-wrapper">
              <Title center={true} content={api.singletons.culture.title_other_2} />
              <p className="o-headline u-text-center">{api.singletons.culture.text_other_2}</p>
            </div>
            <div className="c-where-start">
              <div className="u-wrapper">
                <Title customClasses={["c-where-start__ttl"]} content="Onde tudo <strong>começou</strong>" />
              </div>
              <CardsCarousel modifier="timeline" cards={api.collections.timeline.map(item => {return { title: item.title, text: item.text, year: item.date.split("-")[0]}})} />
            </div>
          </>
        )}
      </Layout>
    </div>
  )
}

export default Cultura
