import React from "react"
import Title from '../title';

const Thumb = props => {
  return (
    <div className="c-initiative-card">
      <img className="c-initiative-card__img" src={props.image} alt={props.title} />
      <div className="c-initiative-card__info">
        <Title
          size={32}
          tag="h3"
          content={props.title}
          customModifiers={["decoration"]}
          customClasses={["c-initiative__ttl"]}
        />
        <p className="c-initiative-card__txt" dangerouslySetInnerHTML={{ __html: props.text }} />
      </div>
    </div>
  )
}

export default Thumb
